import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { debounceTime, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private readonly locationApiUrl = 'https://nominatim.openstreetmap.org/search';
  protected http = inject(HttpClient);

  public setBrowserLocation() {
    // return new Observable((observer) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              long: position.coords.longitude
            };
            this.saveBrowserLocation(pos);
          },
          (error) => {
            this.removeBrowserLocation();
            console.error(error);
          }
        );
      } else {
        console.error('Geolocation is not available in this browser.');
      }
    // });
  }

  public getBrowserLocation() {
    const location = localStorage.getItem("browser-location");
    return !!location ? JSON.parse(location) : null;
  }

  private saveBrowserLocation(value: any) {
    localStorage.setItem("browser-location", JSON.stringify(value));
  }

  private removeBrowserLocation() {
    localStorage.removeItem("browser-location");
  }

  public searchLocation(query: string, limit: number = 10) {
    const queryParams = new HttpParams()
      .set('q', query)
      .set('format', 'json')
      .set('addressdetails', '1')
      .set('countrycodes', 'pl')
      .set('limit', limit);

    return this.http.get(this.locationApiUrl, {
      params: queryParams,
    });
  }

  public lookupLocation() {

  }

  public validAddressTypes() {
    return [
      "administrative",
      "house_number",
      "house",
      "street",
      "suburb",
      "neighbourhood",
      "village",
      "hamlet",
      "town",
      "city",
      "locality",
      "postcode",
      "building",
      "residential",
      "commercial",
      "industrial",
      "place",
      "farm",
      "county",
    ];
  }

  public createShortDescription(address: any) {
    let descriptionParts = [];

    if (address.house_number) {
      descriptionParts.push(address.house_number);
    }

    if (address.road) {
      descriptionParts.push(address.road);
    }

    if (address.city) {
      descriptionParts.push(address.city);
    } else if (address.village) {
      descriptionParts.push(address.village);
    }

    if (address.state) {
      descriptionParts.push(address.state);
    }

    if (address.country) {
      descriptionParts.push(address.country);
    }

    return descriptionParts.join(', ');
  }
}
